@font-face {
  font-family: 'NaturalGrotesk';
  src: url('./fonts/NON\ Natural\ Grotesk-Regular.woff');
}

@font-face {
  font-family: 'NaturalGrotesk2';
  src: url('./fonts/NON\ Natural\ Grotesk-Regular.woff2');
}

@font-face {
  font-family: 'NaturalGroteskMedium';
  src: url('./fonts/NON\ Natural\ Grotesk-Medium.woff');
}

@font-face {
  font-family: 'NaturalGroteskMedium2';
  src: url('./fonts/NON\ Natural\ Grotesk-Medium.woff2');
}

@font-face {
  font-family: 'NaturalGroteskBold';
  src: url('./fonts/NON\ Natural\ Grotesk-Bold.woff');
}
