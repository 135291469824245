@import 'font.css';
@import 'react-tooltip/dist/react-tooltip.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply h-10 text-lg text-white bg-black text-ellipsis w-28 active:shadow-none active:bg-gray-800 hover:cursor-pointer;
  }

  .button.deliver {
    @apply w-max px-2.5;
  }
  .button.logout {
    @apply text-red-error;
  }
  .button.disabled {
    @apply bg-gray-500 border-gray-500 shadow-gray-500;
  }

  .button.padding {
    @apply p-2.5;
  }

  .button.padding-half {
    @apply p-1.5;
  }

  .button.fit {
    @apply px-2 w-fit h-fit;
  }

  #entries {
    @apply border-b border-b-white;
  }

  .tableHeader {
    @apply h-12 text-center text-black bg-white w-36;
  }
  .tableHeader {
    max-width: 7rem;
  }

  .tableElement {
    @apply h-20 text-lg text-center text-white bg-white border border-b-0 border-l border-gray-menu-darker w-28 last:border-r-0;
  }

  .tableElement {
    max-width: 7rem;
  }

  .totalElement {
    @apply h-16 text-lg text-center text-white border border-gray-spacer w-28 bg-gray-menu-dark last:border-r-0;
  }
}

.holiday {
  @apply bg-gray-spacer border-gray-menu-darker;
}

.holiday-fg {
  @apply text-red-error;
}
.holiday-fg > input {
  @apply text-red-error;
}

.root {
  @apply h-screen bg-white;
}

.hourInput {
  @apply w-20 text-lg text-center text-black bg-transparent border-0 active:border-0;
}
.hourInput.neutral {
  @apply text-white bg-black w-52;
}

.hourOption {
  @apply bg-gray-600;
}

.hourInput::-webkit-input-placeholder {
  @apply border-green-100;
}

.hourInput::-webkit-outer-spin-button,
.hourInput::-webkit-inner-spin-button {
  @apply appearance-none;
}

body {
  @apply bg-white;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.react-calendar {
  @apply w-auto h-auto p-5 mt-12 text-center text-black bg-gray-menu-light min-w-fit;
}

.react-calendar {
  max-width: 24rem;
  min-width: 20rem;
}

.react-calendar__navigation {
  @apply flex text-xl font-bold;
}

.react-calendar__navigation__label {
  @apply capitalize;
}

.react-calendar__navigation__arrow {
  @apply flex-grow text-4xl;
}

.react-calendar__month-view__weekdays {
  @apply pt-6 pb-4 text-black;
}

.react-calendar__month-view__days__day {
  @apply py-2 text-lg border border-gray-menu-light;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-gray-menu-darker;
}

.react-calendar__tile--now {
  @apply underline decoration-4;
}

.registeredDate {
  @apply bg-green-100 border border-green-100;
}

.selectedWeekDate {
  @apply border border-black border-x-0;
}

.selectedWeekDate__first {
  @apply border-l;
}

.selectedWeekDate__last {
  @apply border-r;
}

.selectedWeekDate__single {
  @apply border;
}

.commentIcon {
  @apply md:absolute text-gray-menu-dark md:top-1 md:right-1 md:text-lg text-2xl;
}

.commentIcon.active {
  @apply text-black;
}

.commentIcon:hover {
  @apply cursor-pointer;
}

.commentIcon.disabled {
  @apply text-gray-menu-light;
}

.overtimeIcon {
  @apply md:absolute text-gray-menu-dark md:bottom-1 md:right-1 ml-3 text-2xl md:ml-0 md:text-base;
}

.addProjectIcon {
  @apply text-green-100;
}

.removeProjectIcon {
  @apply self-center ml-3 text-red-error;
}

.removeProjectIcon:hover {
  @apply cursor-pointer;
}

.editProjectIcon {
  @apply self-center ml-3 text-gray-500;
}

.editProjectIcon:hover {
  @apply cursor-pointer;
}

.close-comment-field-icon {
  @apply sticky top-0 left-0 text-xl text-black;
}

.close-comment-field-icon:hover {
  @apply cursor-pointer;
}

.removeWorkhourIcon {
  @apply md:absolute md:text-xl text-3xl mr-3 md:mr-0 md:text-transparent md:top-1 md:left-1 cursor-pointer md:cursor-default;
}

.relative:hover > .removeWorkhourIcon {
  @apply text-black cursor-pointer;
}

.version-text:hover > .hash-div {
  @apply visible;
}

.relative:hover > .removeWorkhourIcon.disabled {
  @apply text-transparent cursor-default;
}
@layer components {
  .addWorkhourIcon {
    @apply flex items-center justify-center w-20 h-full mx-auto text-4xl text-transparent;
  }

  .addWorkhourIcon:focus {
    @apply text-gray-menu-darker;
  }

  .relative:hover > .addWorkhourIcon {
    @apply cursor-pointer text-gray-menu-darker;
  }

  .relative:hover > .addWorkhourIcon.disabled {
    @apply text-transparent cursor-default;
  }
}

.tableElement:hover {
  @apply cursor-pointer;
}

.relative:hover {
  @apply cursor-default;
}

.addProjectModal {
  @apply fixed z-10 top-0 left-0 bg-white border border-black p-5 mt-[calc(50vh-theme(space.48))] ml-12 h-80;
}

.comment-dialog-underlay {
  @apply fixed inset-0 z-30 flex items-center justify-center bg-gray-600 bg-opacity-50;
}

.comment-dialog-overlay {
  @apply flex flex-col w-full gap-3 p-3 m-4 mx-2 overflow-hidden bg-white border border-black md:w-96 mt-14;
}

.comment-dialog-overlay > textarea {
  @apply w-full p-2 text-black border border-black resize-none;
}

.stats-container {
  @apply grid justify-around w-full grid-cols-2 grid-rows-4 gap-8 p-5 align-bottom bg-black h-max md:grid-rows-3 md:grid-cols-3 lg:flex lg:flex-row lg:flex-nowrap;
}

.project-list {
  @apply bg-gray-500 p-5 overflow-y-scroll max-h-[calc(100vh-15rem)] w-full m-auto mt-5;
}

.project-item {
  @apply p-1.5 border-b text-white;
}

.project-header {
  @apply p-1.5 text-2xl;
}

.admin-interface {
  @apply flex flex-col sm:flex-row justify-items-start h-[calc(100vh-11rem)];
}

.admin-navbar {
  @apply flex content-center justify-center align-middle;
}

.admin-navbar > .button {
  @apply self-center w-1/2;
}

.navbar-buttons {
  @apply w-auto;
}

.navbar-buttons > button {
  @apply w-auto p-5 text-black bg-white border;
}

.navbar-buttons > .selected {
  @apply text-black bg-green-100;
}

.navbar-buttons > button:hover {
  @apply cursor-pointer;
}

.workerlist {
  @apply flex flex-col w-full overflow-y-auto align-middle border-r flex-nowrap;
}

.workerlist-button {
  @apply w-full p-5 text-left text-black border-b;
}

.admin-worker-page {
  @apply grid w-full grid-cols-12 gap-8 p-8 auto-rows-min auto-cols-max;
}

.admin-worker-page > h2 {
  @apply col-span-12 text-6xl text-center;
}
.admin-worker-page > .projectworker-fields > div > input {
  @apply w-64 p-2;
}

.projectworker-fields {
  @apply col-span-12 lg:col-span-5 xl:col-span-3 lg:row-span-2;
}

.projectworker-wrapper {
  @apply col-span-12 lg:col-span-7 xl:col-span-6;
}

.vacation-transfer {
  @apply col-span-12 lg:col-span-7 xl:col-span-3;
}

.add-project-wrapper {
  @apply sticky top-0 flex justify-between w-full bg-white;
}

.add-project-wrapper > select {
  @apply flex-grow w-fit;
}

.project-text {
  @apply flex justify-between p-2 overflow-y-scroll text-black align-middle border border-l border-r border-black flex-nowrap bg-gray-menu-light;
}

.remove-project-button {
  @apply text-transparent;
}

.project-text:hover > .remove-project-button {
  @apply text-red-700 cursor-pointer;
}

.hour-rate-input {
  @apply w-16 text-gray-800;
}

.input_black {
  @apply text-white bg-black;
}

.focus {
  @apply underline decoration-green-100 decoration-4;
}

.add-button {
  @apply sticky top-0 text-center text-white bg-black border-t border-r border-t-black border-r-black;
}

.move-right {
  @apply ml-0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: none;
  font-size: 1.25rem;
}

kbd {
  @apply p-1 px-2 mx-2 bg-gray-200 border border-black rounded-md;
}

hr {
  @apply my-2;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.tooltip-circle {
  @apply flex justify-center w-6 h-6 rounded-full bg-gray-spacer;
}

.tooltip {
  max-width: 85vw;
  z-index: 10;
}
